import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/auth-context';
import axios from 'axios';
import { toast } from 'react-toastify';
import { rootUrl } from '../../helpers';
import DataTable from 'react-data-table-component';
import { FaThumbsUp } from 'react-icons/fa';

export default function Users() {

   const auth = useAuth()

   const [users, setUsers] = useState([]);

    const fetchData = async () => {
        try {
          const data = await axios.get(rootUrl(`/getUsers/${auth.user.cid}`));
          if (data.status === 200) {
            setUsers(data.data.data.biodata);
          }
        } catch (e) {
          toast.error(e?.message);
        }
      };
    
      useEffect(()=>{
        fetchData()
      }, [])
    
      const columns = [
        { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'First Name', selector: row => row.firstName +' '+row.lastName, sortable: true },
        { name: 'Phone Number', selector: row => row.phoneNumber, sortable: true },
        { name: 'Date of Birth', selector: row => row.dob, sortable: true },
        { name: 'Gender', selector: row => row.gender, sortable: true },
        { name: 'Status', cell:row=>row.mode == 0?'Pending':'Approved' },
        {
          name: 'Actions',
          cell: row => row.mode == 0? <button className='text-green-500' onClick={() => handleApprove(row.userId)}> <FaThumbsUp /> </button>:'',
          button: true
        }
      ];
      const handleApprove = async (id) => {
        try {
            const data = await axios.get(rootUrl(`/approve/${id}`));
            if (data.status === 200) {
                toast.success("Successful");
                fetchData()
            }
          } catch (e) {
            toast.error(e?.message);
          }
      };

  return (
    <div>
        <DataTable
        title="User List"
        columns={columns}
        data={users}
        pagination
        highlightOnHover
        pointerOnHover
      />
    </div>
  )
}

