import React from 'react'

export default function Dashboard() {
  return (
    <div className='bg-white p-4 rounded-lg shadow'>

    <div className='flex items-center justify-between font-semi-bold text-lg'>
      <span className='flex items-center'> Dashboard</span> 
    </div>
                <hr />
    </div>
  )
}
