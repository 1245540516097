import logo from './logo.svg';
import './App.css';
import { Typography, Button } from "@material-tailwind/react";

function App({ children }) {
  return (
    <>
      {children}
    </>
  );
}

export default App;
