import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { formatToZMW, rootUrl } from '../../helpers';
import { useAuth } from '../../context/auth-context';
import { toast } from 'react-toastify';
import { FaMoneyBill, FaPlus } from 'react-icons/fa';
import ModalDefault from '../../components/ModalDefault';
import FormGenerator from '../../components/formGenerator';

export default function Budget() {

    const [budget, setBudget] = useState([]);
    const [coa, setCoa] = useState([]);
    const [dept, setDept] = useState([]);
    const [program, setProgram] = useState([]);
    const [subProgram, setSubProgram] = useState([]);
    const [output, setOutput] = useState([]);
    const [units, setUnits] = useState([]);
    const [councils, setCouncil] = useState([]);
    const [amounts, setAmounts] = useState([]);

    const auth = useAuth();

    const fetchData = async () => {
        try{
            const data = await axios.get(rootUrl(`/getProjects/${auth.user.id}`))
            setBudget(data.data.data.budget);
            setCoa(data.data.data.coa);
            setDept(data.data.data.dept);
            setProgram(data.data.data.program);
            setSubProgram(data.data.data.subProgram);
            setOutput(data.data.data.output);
            setUnits(data.data.data.units);
            setCouncil(data.data.data.councils);

        }catch(e){
            toast.error(e?.message)
        }
    }

    const getData = async () => {
        try{
            const data = await axios.get(rootUrl(`/getDeptAmount/${auth.user.cid}`))
            setAmounts(data.data.data)
        }catch(e){
            toast.error(e?.message)
        }
    }

    useEffect(()=>{
        getData()
    },[])

    useEffect(()=>{
        fetchData()
    },[amounts])


    const fields = [
        {type: 'select', name: 'departmentCode', label: 'Department', required: true, dependentFields: ['unit'], options: dept.map(res => ({
            value: res.id,
            label: res.name,
          }))},
          {type: 'number', name: 'amount', label: 'Amount', required: true},
    ]

    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})
    const handleClose = () => {
        setModal({...modal, open: false })   
    }

    const submitSetting = async (data)=>{
        data['councilCode'] = auth.user.cid;
        data['addedBy'] = auth.user.id;

        try{
            const response = await axios.post(rootUrl(`/addDeptAmount`), data)
            if(response.data.status == 200){
                toast.success('Submitted successfully')
                getData()
                setModal({...modal, open: false })  
            }else{
                toast.error(response.data.message)
            }
        }catch(e){
            toast.error(e?.message)
        }
    }

    const addSett = async () => {
        setModal({
            title:'Add Amount',
            open:true,
            size:'sm',
            body:<FormGenerator fields={fields} onSubmit={submitSetting} />
              })
    }
    

   

  return (
    <div className='bg-white p-4 rounded-lg shadow'>

       
            <div className='flex items-center justify-between font-semi-bold text-lg'>
                   <span className='flex items-center'><FaMoneyBill className='mr-2' /> Budget</span> 
                   <button onClick={()=>addSett()} className='flex items-center border bg-blue-500 text-white rounded px-2'> <FaPlus/> Add</button>
                </div>
        
            <hr className='my-3' />


        <table className='w-full mt-5'>
            <thead>
                <tr className='bg-blue-500 text-white'>
                    <th className='p-2 border'>Department</th>
                    <th className='p-2 border'>Allocated Amount</th>
                    <th className='p-2 border'>Budgeted Amount</th>
                    <th className='p-2 border'>Variance</th>
                </tr>
            </thead>
            <tbody>
            {
    dept && (
        <>
            {dept.map((res) => {
                const amount = amounts.find(a => a.departmentCode === res.id)?.amount || 0;
                console.log(budget.map(res=>res.coa.substring(0,1)))
                const budgetTotal = budget
                    .filter(a => a.dept === res.id && a.coa.substring(0,1) !== '1')
                    .map(row => parseFloat(row.price) * parseFloat(row.qty) * parseFloat(row.frequency))
                    .reduce((a, b) => a + b, 0);
                const balance = amount - budgetTotal;
                return (
                    <tr key={res.id}>
                        <td className='p-2 border'>{res.name}</td>
                        <td className='p-2 border'>{formatToZMW(amount)}</td>
                        <td className='p-2 border'>{formatToZMW(budgetTotal)}</td>
                        <td className='p-2 border'>{formatToZMW(balance)}</td>
                    </tr>
                );
            })}

            {/* Totals Row */}
            <tr>
                <td className='p-2 border'><strong>Total</strong></td>
                <td className='p-2 border'><strong>
                    {formatToZMW(dept
                        .map(res => parseFloat(amounts.find(a => a.departmentCode === res.id)?.amount || 0))
                        .reduce((a, b) => a + b, 0)
                    )}
                </strong></td>
                <td className='p-2 border'><strong>
                    {formatToZMW(dept
                        .map(res => budget
                            .filter(a => a.dept === res.id && a.coa.substring(0,1) !== '1')
                            .map(row => parseFloat(row.price) * parseFloat(row.qty) * parseFloat(row.frequency))
                            .reduce((a, b) => a + b, 0)
                        )
                        .reduce((a, b) => a + b, 0)
                    )}
                </strong></td>
                <td className='p-2 border'><strong>
                    {formatToZMW(dept
                        .map(res => {
                            const amount = amounts.find(a => a.departmentCode === res.id)?.amount || 0;
                            const budgetTotal = budget
                                .filter(a => a.dept === res.id && a.coa.substring(0,1) !== '1')
                                .map(row => parseFloat(row.price) * parseFloat(row.qty) * parseFloat(row.frequency))
                                .reduce((a, b) => a + b, 0);
                            return amount - budgetTotal;
                        })
                        .reduce((a, b) => a + b, 0)
                    )}
                </strong></td>
            </tr>
        </>
    )
}

            </tbody>
        </table>

    <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
    
    </div>
  )
}
