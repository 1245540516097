import React, { useEffect, useState, useMemo, useRef  } from 'react'
import FormGenerator from '../../components/formGenerator'
import axios from 'axios'
import { toast } from 'react-toastify'
import { formatToZMW, rootUrl } from '../../helpers'
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Select from 'react-select';
import { FaPlus, FaSpinner } from 'react-icons/fa'
import ModalDefault from '../../components/ModalDefault'
import * as XLSX from 'xlsx';
import { useAuth } from '../../context/auth-context'
import { IoCompassOutline } from 'react-icons/io5'
import DataTable from './dataTable'
import ConfirmationDialog from '../../components/ConfirmationDialog'

export default function LandingPage() {
  const [loader, setLoader] = useState(true);
  const [budget, setBudget] = useState([]);
  const [coa, setCoa] = useState([]);
  const [dept, setDept] = useState([]);
  const [program, setProgram] = useState([]);
  const [subProgram, setSubProgram] = useState([]);
  const [output, setOutput] = useState([]);
  const [units, setUnits] = useState([]);
  const [modal, setModal] = useState({title: '', open: false, body: '', size: 'sm'});
  const [councils, setCouncil] = useState([]);

  const [amounts, setAmounts] = useState([]);
  const [deptAmounts, setDeptAmounts] = useState([]);

  const formRef = useRef(null);

  const [fields, setFields] = useState([]);
  const [revenueFields, setRevFields] = useState([]);

  const handleClose = () => {
    setModal({...modal, open: false });   
  };

  const auth = useAuth();

  const fetchData = async () => {
    try {
      const data = await axios.get(rootUrl(`/getProjects/${auth.user.id}`));
      if (data.status === 200) {
        setLoader(false);
        setBudget(data.data.data.budget);
        setCoa(data.data.data.coa);
        setDept(data.data.data.dept);
        setProgram(data.data.data.program);
        setSubProgram(data.data.data.subProgram);
        setOutput(data.data.data.output);
        setUnits(data.data.data.units);
        setCouncil(data.data.data.councils);
        setDeptAmounts(data.data.data.deptAmounts);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const getData = async () => {
    try{
        const data = await axios.get(rootUrl(`/getDeptAmount/${auth.user.cid}`))
        setAmounts(data.data.data)
        console.log(data.data.data)
    }catch(e){
        toast.error(e?.message)
    }
}

  useEffect(() => {
    fetchData();
    getData()
  }, []);

  const [display, setDisplay] = useState('');

  function changeDep(name, value){
    const mun =  amounts.find(a=>a.departmentCode == value)?.amount || 0
    const budgetTotal = deptAmounts.find(a=>a.dept == value)?.amount || 0
    const balance = parseFloat(mun) - parseFloat(budgetTotal);
    setDisplay(`Allocated:${formatToZMW(mun)} - Budgeted:${formatToZMW(budgetTotal)} = Variance:${formatToZMW(balance)}`)

    if(balance <= 0){
      toast.info('You have already used up your allocated amount')
    }
  }

  useEffect(() => {
    setFields([
      
      {type: 'select', name: 'dept', label: 'Department', required: true, dependentFields: ['unit'], onChange:changeDep, options: dept.map(res => ({
        value: res.id,
        label: res.name,
      }))},
      {type: 'select', name: 'program', label: 'Program', required: true, dependentFields: ['subprogram', 'output'],  options: program.map(res => ({
        value: res.programCode,
        label: res.name,
      }))},
      {type: 'select', name: 'subprogram', label: 'Sub Program', required: true, options: subProgram.map(res => ({
        value: res.subProgramCode,
        label: res.name,
        dependsOn:'program',
        dependentValue:res.programCode
      }))},
      {type: 'select', name: 'output', label: 'Output Indicators', required: true, options: output.map(res => ({
        value: res.indicators,
        label: res.keyIndicators,
        dependsOn:'program',
        dependentValue:res.programCode
      }))},
      {type: 'select', name: 'unit', label: 'Units', required: true, options: units.map(res => ({
        value: res.name,
        label: res.name,
        dependsOn:'dept',
        dependentValue:res.departmentCode
      }))},
      {type: 'text', name: 'toy', label: 'TOI', required: true},
      {type: 'select', name: 'coa', label: 'COA', required: true, options: coa.filter(a=>a.account.substring(0,1) != "1").map(res => ({
        value: res.account,
        label: res.name + ' : ' + res.account,
      }))},
      {type: 'text', name: 'description', label: 'Description', required: true},
      {type: 'number', name: 'price', label: 'Rate/Price', onChange:change , required: true},
      {type: 'number', name: 'qty', label: 'Quanitity', onChange:change, required: true},
      {type: 'number', name: 'frequency', label: 'Frequency',onChange:change, required: true},
      {type: 'text', name: 'objective', label: 'Objective'}, 
      {type: 'text', name: 'measure', label: 'Unit of Measure', required: true}, 
    ]);

    setRevFields([
       {type: 'select', name: 'dept', label: 'Department', required: true, dependentFields: ['unit'], options: dept.map(res => ({
        value: res.id,
        label: res.name,
      }))},
      {type: 'select', name: 'output', label: 'Output Indicators', required: true, options: output.map(res => ({
        value: res.indicators,
        label: res.keyIndicators,
        dependsOn:'program',
        dependentValue:res.programCode
      }))},
      {type: 'select', name: 'unit', label: 'Units', required: true, options: units.map(res => ({
        value: res.name,
        label: res.name,
        dependsOn:'dept',
        dependentValue:res.departmentCode
      }))},
      {type: 'text', name: 'toy', label: 'TOI', required: true},
      {type: 'select', name: 'coa', label: 'COA', required: true, options: coa.filter(a=>a.account.substring(0,1) == "1").map(res => ({
        value: res.account,
        label: res.name + ' : ' + res.account,
      }))},
      {type: 'text', name: 'description', label: 'Description', required: true},
      {type: 'number', name: 'price', label: 'Rate/Price', onChange:change , required: true},
      {type: 'number', name: 'qty', label: 'Quanitity', onChange:change, required: true},
      {type: 'number', name: 'frequency', label: 'Frequency',onChange:change, required: true},
      {type: 'text', name: 'objective', label: 'Objective'}, 
      {type: 'text', name: 'measure', label: 'Unit of Measure', required: true}, 
    ])
  }, [dept]);

const [calculatedValue, setcalculatedValue] = useState(0)

var price = 0
var qty = 0
var freq = 0

  const change = (name, value) =>{
    
    if(name == 'price'){
        price = parseFloat(value)
    }
    if(name == 'qty'){
      qty = parseFloat(value)
    }

    if(name == 'frequency'){
      freq = parseFloat(value)
    }
    setcalculatedValue(price*qty*freq)
  }


  useEffect(() => {
    const element = document.getElementById('postValue');
    if (element) {
      element.innerHTML = 'Calculated Value: ' +formatToZMW(calculatedValue);
    }
  }, [calculatedValue]);

  useEffect(() => {
    const element = document.getElementById('displayValue');
    if (element) {
      element.innerHTML = display;
    }
  }, [display]);

  
  const handleReset = () => {

    if (formRef.current) {
      formRef.current.resetFields(['objective', 'frequency', 'qty', 'price', 'description', 'measure']);
    }
  };


  const onsubmit = async (data) => {
    data['year'] = 2024;
    data['userId'] = auth.user.id;
    data['councilId'] = auth.user.cid;

    if(data['program'] == undefined){
      data['program'] = data['coa'].substring(0,3)
      data['subprogram'] = data['coa'].substring(3,6)
    }

    try {
      const response = await axios.post(rootUrl(`/addProject`), data);
      if (response.status === 200) {
        toast.success('Added successfully');
        // setModal({...modal, open: false }); 
        setBudget(prevItems => [...prevItems, response.data.data]);
        handleReset()
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };


  const addBudget = () => {
    setcalculatedValue(0)
    setModal({
      title: 'Add Expenditure Budget',
      open: true,
      size: 'sm',
      body:<> <FormGenerator ref={formRef} fields={fields} onSubmit={onsubmit} /> 
      <hr className='my-2' />
      <div className='flex justify-between'>
      <div className=' m-5 font-semibold text-lg ' id="postValue">Calculated Value: {calculatedValue}</div>
      <div className=' m-5 font-semibold text-lg ' id="displayValue">{display}</div>
      </div>
       </>
      
    });
  };

  const addRevenue = () => {
    setcalculatedValue(0)
    setModal({
      title: 'Add Revenue Budget',
      open: true,
      size: 'sm',
      body:<> <FormGenerator ref={formRef} fields={revenueFields} onSubmit={onsubmit} /> 
      <hr className='my-2' />
      <div className='flex justify-between'>
      <div className=' m-5 font-semibold text-lg ' id="postValue">Calculated Value: {calculatedValue}</div>
      <div className=' m-5 font-semibold text-lg ' id="displayValue">{display}</div>
      </div>
      </>
    });
  };

  

  const deleteItem = async (id) => {


    setMod({
      open: true,
      title: 'Warning',
      message: 'Are you sure you want to delete this items?',
      onConfirm: async () => {
        try{
          const response = await axios.get(rootUrl(`/deleteItem/${id}`))

          if(response.data.status == 200){
            toast.success('Deleted Successfully')
            setBudget((prevItems) => prevItems.filter(item => item.id !== id));
            handleModClose()
          }else{
            toast.error('Something went wrong')
          }

      }catch(e){
          toast.error(e?.message)
      }
      }
    })

    
}
const [mod, setMod] = useState({ open: false, title: '', message: '', onConfirm: null })

const handleModClose = () =>{
  setMod({ ...mod, open: false });
}

  return (
    <div>
      {loader ? (
        <div className='flex justify-center items-center h-full w-full text-blue-900 text-5xl p-5 m-5 '>
        <FaSpinner size={50} className="spinner"  />
        </div>
        
      ) : (
        <div className='m-5 p-5 '>
          <button onClick={addBudget} className='float-right border flex rounded bg-blue-900 p-2 items-center text-white'>
            <FaPlus /> Expenditure
          </button>

          <button onClick={addRevenue} className='float-right border flex rounded bg-blue-900 p-2 items-center text-white'>
            <FaPlus />Revenue
          </button>
          <DataTable
           councils={councils}
            data={budget} 
            coa={coa} 
            depts={dept}
            progarms={program}
            subPrograms={subProgram} 
            output={output}
            units={units}
            onDelete={deleteItem}
             />
          <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body} />

          <ConfirmationDialog
                open={mod.open}
                title={mod.title}
                message={mod.message}
                onConfirm={mod.onConfirm}
                onCancel={handleModClose}
            />
        </div>
      )}
    </div>
  );
}
