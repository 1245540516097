import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { rootUrl } from '../../helpers';
import { useAuth } from '../../context/auth-context';
import { toast } from 'react-toastify';

export default function Input() {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const auth = useAuth();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }

     // Read the file
     const reader = new FileReader();
     reader.onload = (e) => {
       // Parse the file
       const binaryStr = e.target.result;
       const workbook = XLSX.read(binaryStr, { type: 'binary' });
 
       // Assuming the first sheet is used
       const sheetName = workbook.SheetNames[0];
       const worksheet = workbook.Sheets[sheetName];
       const jsonData = XLSX.utils.sheet_to_json(worksheet);
 
       // Extract headers for columns
       const headers = Object.keys(jsonData[0] || {}).map(header => ({
         name: header,
         selector: row => row[header],
         sortable: true
       }));
 
       setColumns(headers);
       setData(jsonData);
       
     };
     reader.readAsBinaryString(file);
  };

  const handleFileUpload = () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }
    sendToBackend(data)
   
  };

  

  const sendToBackend = async (data) => {

    const fd = {
        data:data,
        user:auth.user.id
    }

    try {
      const response = await axios.post(rootUrl(`/importProjects`), fd);
      if(response.data.status == 200){
        toast.success("Successful")
        setData('')
      }
    } catch (error) {
      console.error('Error sending data:', error);
      toast.error(error?.message)
    }
  };

  return (
    <div className='bg-white p-4 rounded-lg shadow'>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
      />
      <button className='border bg-blue-500 text-white p-2 rounded' onClick={handleFileUpload}> Send</button>

      {data.length > 0 && (
        <table className='mt-3'>
          <thead>
            <tr className='bg-blue-500 text-white p-2'>
              {columns.map((col, index) => (
                <th key={index}>{col.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>{row[col.name]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
