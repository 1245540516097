import React from 'react'
import { FaSignOutAlt } from 'react-icons/fa';
import { Outlet } from "react-router-dom";
import { useAuth } from '../context/auth-context';

export default function Userlayout() {

  const auth = useAuth()

 
  const signOut = () => {
    auth.logOut(()=>{
      window.location.reload()
    })
  }

  return (
    <div className='bg-gray-100'>
      <div className='bg-white text-blue-900 flex w-full p-4 h-[85px] items-center justify-between shadow-lg'>
        <span className=' flex items-center font-bold text-2xl '>
          <img src="/logo.jpg" className='w-[75px] mr-1' alt="" /> Gramosi
        </span>
        <FaSignOutAlt onClick={signOut} title='logout' className='text-2xl'/>
      </div>
      <Outlet/>
      </div>
  )
}
