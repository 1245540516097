import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import * as XLSX from 'xlsx';
import { formatToZMW, rootUrl } from '../../helpers';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function DataTable({ data, coa, depts, progarms, subPrograms, output, units, councils, onDelete }) {
    const [filters, setFilters] = useState({
        dept: null,
        program: null,
        councilId: null,
        coa: null,
    });

    // Custom filter function for global search
    function globalFilterFunction(rows, columnIds, filterValue) {
        return rows.filter(row => {
            return columnIds.some(columnId => {
                const cellValue = row.values[columnId];
                return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
            });
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Department',
                accessor: 'dept',
                Cell: ({ value }) => {
                    const department = depts.find((dept) => dept.id === value);
                    return department ? department.name : 'Unknown Department';
                },
            },
            {
                Header: 'Program',
                accessor: 'program',
                Cell: ({ value }) => {
                    const program = progarms.find((p) => p.programCode === value);
                    return program ? program.name : 'Unknown Program';
                },
            },
            {
                Header: 'Subprogram',
                accessor: 'subprogram',
                Cell: ({ value }) => {
                    const sub = subPrograms.find((p) => parseInt(p.subProgramCode) == parseInt(value));
                    return sub ? sub.name : 'Unknown Subprogram';
                },
            },
            {
                Header: 'COA',
                accessor: 'coa',
                Cell: ({ value }) => {
                    const coas = coa.find((c) => c.account === value);
                    return coas ? `${coas.name} ${coas.account}` : 'Unknown COA';
                },
            },
            {
                Header: 'Output indicator',
                accessor: 'output',
                
            },
            {
                Header: 'Unit',
                accessor: 'unit',
               
            },
            {
                Header: '(TOI)',
                accessor: 'toy',
            },
            {
                Header: 'Amount',
                Cell: ({ row }) => {
                    return formatToZMW(parseFloat(row.original.price) * parseFloat(row.original.qty) * parseFloat(row.original.frequency));
                },
                Footer: (info) => {
                    const total = useMemo(
                        () =>
                            info.rows.reduce((sum, row) => {
                                return sum + parseFloat(row.original.price) * parseFloat(row.original.qty) * parseFloat(row.original.frequency);
                            }, 0),
                        [info.rows]
                    );
                    return `Total: ${formatToZMW(total.toFixed(2))}`;
                },
            },
            
            {
                Header: 'Measure',
                accessor: 'measure',
            },
            {
                Header: 'Action',
                Cell: ({ row }) => {
                    return <FaTrash onClick={()=>onDelete(row.original.id)} className='text-red-500'/>
                },
            },
        ],
        [coa, councils, depts, output, progarms, subPrograms, units]
    );

    

    

    const filteredData = useMemo(() => {
        return data.filter((item) => {
            return (
                (!filters.dept || item.dept === filters.dept) &&
                (!filters.program || item.program === filters.program) &&
                (!filters.councilId || item.councilId === filters.councilId) &&
                (!filters.coa || item.coa.startsWith(filters.coa))  
            );
        });
    }, [data, filters]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setGlobalFilter,
        state: { pageIndex, globalFilter },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0 },
            globalFilter: globalFilterFunction, // Use the custom global filter function
        },
        useGlobalFilter,
        usePagination
    );

    const handleFilterChange = (name) => (selectedOption) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleCoaFilter = () => (selectedOption) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            coa: selectedOption ? selectedOption.value : null,
        }));
    };

    

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(
            filteredData.map((item) => ({
                CouncilCode: councils.find((d) => d.code === item.councilId)?.code || 'Unknown',
                CouncilName: councils.find((d) => d.code === item.councilId)?.name || 'Unknown',
                DepartmentCode: depts.find((d) => d.id === item.dept)?.id || 'Unknown',
                DepartmentName: depts.find((d) => d.id === item.dept)?.name || 'Unknown',
                ProgrammeCode: progarms.find((d) => d.programCode === item.program)?.programCode || 'Unknown',
                ProgrammeName: progarms.find((p) => p.programCode === item.program)?.name || 'Unknown',
                SubProgrammeCode: subPrograms.find((s) => parseInt(s.subProgramCode) === parseInt(item.subprogram))?.subProgramCode || 'Unknown',
                SubProgrammeName: subPrograms.find((s) => parseInt(s.subProgramCode) === parseInt(item.subprogram))?.name || 'Unknown',
                ChartOfAccountCode: coa.find((c) => c.account === item.coa)?.account || 'Unknown',
                ChartOfAccountName: coa.find((c) => c.account === item.coa)?.name || 'Unknown',
                KeyIndicator: output.find((o) =>o.indicators === item.output)?.keyIndicators|| 'Unknown',
                OutputIndicator: item.output || 'Unknown',
                TOI: item.toy || 'Unknown',
                Unit: item.unit || 'Unknown',
                Description: item.description || 'Unknown',
                qty: item.qty || 'Unknown',
                frequency: item.frequency || 'Unknown',
                price: item.price || 'Unknown',
                Amount: parseFloat(item.price) * parseFloat(item.qty) * parseFloat(item.frequency),
                objective: item.objective || 'Unknown',
                measure: item.measure || 'Unknown',
                
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        XLSX.writeFile(wb, 'data.xlsx');
    };

    return (
        <>
            <div className="flex space-x-4 mb-4">
                <Select
                    placeholder="Filter by Department"
                    options={[...new Set(data.map((item) => item.dept))].map((dept) => ({
                        value: dept,
                        label: depts.find((a) => a.id === dept)?.name || 'Unknown Department',
                    }))}
                    onChange={handleFilterChange('dept')}
                />
                <Select
                    placeholder="Filter by Program"
                    options={[...new Set(data.map((item) => item.program))].map((program) => ({
                        value: program,
                        label: progarms.find((p) => p.programCode === program)?.name || 'Unknown Program',
                    }))}
                    onChange={handleFilterChange('program')}
                />
                
                <Select
                    placeholder="Filter by COA"
                    options={[...new Set(data.map((item) => item.coa))].map((co) => ({
                        value: co,
                        label: coa.find((c) => c.account === co)?.name || 'Unknown COA',
                    }))}
                    onChange={handleFilterChange('coa')}
                />

                <Select
                    placeholder="Filter by COA Type"
                    options={[
                        {value:1, label:'Revenue'},
                        {value:2, label:'Expense'},
                        {value:3, label:'Assets'},
                        {value:4, label:'Liabilities'},
                        {value:5, label:'Equity'},
                        {value:6, label:'Reserves'},

                    ]}
                    onChange={handleCoaFilter()}
                />
                <button onClick={exportToExcel} className="ml-4 p-2 bg-blue-900 text-white rounded">
                    Export to Excel
                </button>
            </div>

            {/* <input
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search..."
                className="mb-4 p-2 border rounded-md"
            /> */}

            <table {...getTableProps()} className="min-w-full bg-white mt-5">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="px-6 py-3 border-b-2 border-gray-200 bg-blue-900 text-left text-white text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                        className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            className='bg-blue-900 text-white'
                        {...headerGroup.getFooterGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <td
                                    {...column.getFooterProps()}
                                    className="px-6 py-3 border-t-2 border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    {column.render('Footer')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>

            <div className="pagination mt-4">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
            </div>
        </>
    );
}
