import React, {useState, useEffect} from 'react'
import { FaCogs, FaPlus, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify';
import axios from 'axios';
import { Chip } from '@material-tailwind/react';
import { rootUrl } from '../../helpers';
import FormGenerator from '../../components/formGenerator';
import ModalDefault from '../../components/ModalDefault';
import { useAuth } from '../../context/auth-context';


export default function Settings() {

    const auth = useAuth();
    const [modal, setModal] = useState({title:'', open:false, body:'', size:'sm'})
    
    let type = ''

    
    const [budget, setBudget] = useState([]);
    const [coa, setCoa] = useState([]);
    const [dept, setDept] = useState([]);
    const [program, setProgram] = useState([]);
    const [subProgram, setSubProgram] = useState([]);
    const [output, setOutput] = useState([]);
    const [units, setUnits] = useState([]);
    const [councils, setCouncil] = useState([]);


    const handleClose = () => {
        setModal({...modal, open: false })   
    }

   

    const fetchData = async () => {
        try{
            const data = await axios.get(rootUrl(`/getProjects/${auth.user.id}`))
            setBudget(data.data.data.budget);
            setCoa(data.data.data.coa);
            setDept(data.data.data.dept);
            setProgram(data.data.data.program);
            setSubProgram(data.data.data.subProgram);
            setOutput(data.data.data.output);
            setUnits(data.data.data.units);
            setCouncil(data.data.data.councils);
        }catch(e){
            toast.error(e?.message)
        }
    }

    useEffect(()=>{
        fetchData()
    },[])

    const submitSetting = async (data) => {
        data['type'] = type
        try{
            const response = await axios.post(rootUrl(`/settings`), data)
            if(response.data.status == 200){
                toast.success('Submitted successfully')
                fetchData()
                setModal({...modal, open: false })  
            }else{
                toast.error(response.data.message)
            }
        }catch(e){
            toast.error(e?.message)
        }
    }

    const addSett = (name) => {
        type = name

        console.log(type)

        var fields = [
            {type:'text', name:'name', label:'Name'},
        ]

        if(type === 'council'){
            fields = [
                {type:'text', name:'code', label:'Code'},
                {type:'text', name:'name', label:'Name'},
            ]
        }

        if(type === 'department'){
            fields = [
                {type:'text', name:'id', label:'Id'},
                {type:'text', name:'name', label:'Name'},
            ]
        }

        if(type === 'program'){
            fields = [
                {type:'text', name:'programCode', label:'Code'},
                {type:'text', name:'programName', label:'Name'},
            ]
        }

        if(type === 'sub'){
            fields = [
                {type:'text', name:'programCode', label:'Code'},
                {type:'text', name:'name', label:'Name'},
                {type: 'select', name: 'subProgramCode', label: 'Program', required: true, dependentFields: ['subprogram', 'output'], options: program.map(res => ({
                    value: res.id,
                    label: res.name,
                  }))},
            ]
        }

        if(type === 'unit'){
            fields = [
                {type:'text', name:'name', label:'Name'},
                {type: 'select', name: 'departmntCode', label: 'Department', required: true, options: dept.map(res => ({
                    value: res.id,
                    label: res.name,
                  }))},
            ]
        }

        setModal({
            title:'Add '+name,
            open:true,
            size:'sm',
            body:<FormGenerator fields={fields} onSubmit={submitSetting} />
              })
    }

  return (
    <div className='bg-white p-4 rounded-lg shadow-lg'>

<div className='flex justify-between '>
    <div className='flex items-center font-semi-bold text-lg'>
            <FaCogs className='mr-2' /> Setup
        </div>
        
    </div>
        <hr className='my-3' />
        <div className="flex flex-wrap mx-4">
        <div className="w-full lg:w-1/4 xl:w-1/4 px-4 mb-4">
            <div className='shadow-md border rounded-lg p-2 m-2 hidden'>
                <div className='flex justify-between'>
                    <span className='font-bold text-lg'>Councils</span>
                    <button onClick={()=>addSett("council")} className='flex items-center border bg-blue-500 text-white rounded px-2'> <FaPlus/> Add</button>
                </div>
                <hr className='my-3' />
                {councils &&
                     <ul>
                     {councils.map((entry, idx) => (
                         <li key={idx} className="flex items-center justify-between m-2">
                             <div className="flex items-center gap-4">
                             <Chip
                                 value={idx + 1}
                                 variant="ghost"
                                 size="sm"
                                 className="rounded-full"
                             />
                             {entry.name}
                             </div>    
                             <FaTrash color='red'/>                        
                         </li>
                     ))}
                     </ul>
                }
            </div>

            <div className='shadow-md border rounded-lg p-2 m-2'>
                <div className='flex justify-between'>
                    <span className='font-bold text-lg'>Departments</span>
                    <button onClick={()=>addSett("department")} className='flex items-center border bg-blue-500 text-white rounded px-2'> <FaPlus/> Add</button>
                </div>
                <hr className='my-3' />
                {councils &&
                     <ul>
                     {dept.map((entry, idx) => (
                         <li key={idx} className="flex items-center justify-between m-2">
                             <div className="flex items-center gap-4">
                             <Chip
                                 value={idx + 1}
                                 variant="ghost"
                                 size="sm"
                                 className="rounded-full"
                             />
                             {entry.name}
                             </div>    
                             <FaTrash color='red'/>                        
                         </li>
                     ))}
                     </ul>
                }
            </div>
        </div>

        <div className="w-full lg:w-1/4 xl:w-1/4 px-4 mb-4">
            <div className='shadow-md border rounded-lg p-2 m-2'>
                <div className='flex justify-between'>
                    <span className='font-bold text-lg'>Programs</span>
                    <button onClick={()=>addSett("program")} className='flex items-center border bg-blue-500 text-white rounded px-2'> <FaPlus/> Add</button>
                </div>
                <hr className='my-3' />
                
                {program &&
                     <ul>
                     {program.map((entry, idx) => (
                         <li key={idx} className="flex items-center justify-between m-2">
                             <div className="flex items-center gap-4">
                             <Chip
                                 value={idx + 1}
                                 variant="ghost"
                                 size="sm"
                                 className="rounded-full"
                             />
                             {entry.name}
                             </div>   
                             <FaTrash color='red'/>                               
                         </li>
                     ))}
                     </ul>
                }
            </div>
        </div>


        <div className="w-full lg:w-1/4 xl:w-1/4 px-4 mb-4">
            <div className='shadow-md border rounded-lg p-2 m-2'>
                <div className='flex justify-between'>
                    <span className='font-bold text-lg'>SubPrograms</span>
                    <button onClick={()=>addSett("sub")} className='flex items-center border bg-blue-500 text-white rounded px-2'> <FaPlus/> Add</button>
                </div>
                <hr className='my-3' />
                
                {subProgram &&
                     <ul>
                     {subProgram.map((entry, idx) => (
                         <li key={idx} className="flex items-center justify-between m-2">
                             <div className="flex items-center gap-4">
                             <Chip
                                 value={idx + 1}
                                 variant="ghost"
                                 size="sm"
                                 className="rounded-full"
                             />
                             {entry.name}
                             </div>   
                             <FaTrash color='red'/>                               
                         </li>
                     ))}
                     </ul>
                }
            </div>
        </div>    

                <div className="w-full lg:w-1/4 xl:w-1/4 px-4 mb-4">
            <div className='shadow-md border rounded-lg p-2 m-2'>
                <div className='flex justify-between'>
                    <span className='font-bold text-lg'>Units</span>
                    <button onClick={()=>addSett("unit")} className='flex items-center border bg-blue-500 text-white rounded px-2'> <FaPlus/> Add</button>
                </div>
                <hr className='my-3' />
                
                {units &&
                     <ul>
                     {units.map((entry, idx) => (
                         <li key={idx} className="flex items-center justify-between m-2">
                             <div className="flex items-center gap-4">
                             <Chip
                                 value={idx + 1}
                                 variant="ghost"
                                 size="sm"
                                 className="rounded-full"
                             />
                             {entry.name}
                             </div>   
                             <FaTrash color='red'/>                               
                         </li>
                     ))}
                     </ul>
                }
            </div>
        </div>     

        </div>
        <ModalDefault title={modal.title} size={modal.size} open={modal.open} onClose={handleClose} body={modal.body}  />
    </div>
  )
}
