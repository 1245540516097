
import LandingPage from "./pages/user"
import { RequireAuth } from "./context/auth-context"
import Userlayout from "./layouts/user-layout"
import { Children } from "react"
import AdminLayout from "./layouts/admin-layout"
import Settings from "./pages/admin/settings"
import Dashboard from "./pages/admin"
import Users from "./pages/admin/users"
import Input from "./pages/admin/input"
import Budget from "./pages/admin/budget"
const routes = [
    {
        path: "/",
        element: <RequireAuth><Userlayout /></RequireAuth>,
        children:[
            {
                path: "/",
                element: <LandingPage />,
            }
        ]
    },
    {
        path: "/admin",
        element: <RequireAuth><AdminLayout /></RequireAuth>,
        children:[
            {
                path: "/admin",
                element: <Dashboard />,
            },
            {
                path: "/admin/settings",
                element: <Settings />,
            },
            {
                path: "/admin/users",
                element: <Users />,
            },
            {
                path: "/admin/input",
                element: <Input />,
            },
            {
                path: "/admin/budget",
                element: <Budget />,
            },
        ]
    },
]

export default routes