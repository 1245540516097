import { Typography } from '@material-tailwind/react';
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { FiSend } from 'react-icons/fi';
import Select from 'react-select';

const FormGenerator = forwardRef(({ fields, sections, onSubmit, initialValues = {}, componentChange }, ref) => {
  const [formData, setFormData] = useState({ ...initialValues });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState({});

  const validateField = (field, value) => {
    const { required, minLength, maxLength, label, type } = field;
    let error = "";

    if (required) {
      if (type === 'checkbox') {
        if (!value || value.length === 0) {
          error = `${label} is required.`;
        }
      } else {
        if (!value) {
          error = `${label} is required.`;
        }
      }
    } else if (value && type === 'string') {
      if (minLength && value.length < minLength) {
        error = `${label} must be at least ${minLength} characters.`;
      } else if (maxLength && value.length > maxLength) {
        error = `${label} must be less than ${maxLength} characters.`;
      }
    }
    return error;
  };

  const handleChange = (field) => (event) => {
    const { name, onChange, type } = field;
    let value;
    if (type === 'checkbox') {
      const { checked, value: checkboxValue } = event.target;
      const currentValues = formData[name] || [];
      if (checked) {
        value = [...currentValues, checkboxValue];
      } else {
        value = currentValues.filter(item => item !== checkboxValue);
      }
    } else
    if(type === 'select') {
      value = event.value;
    }else{
      value = event.target.value;
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const error = validateField(field, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    if (onChange) {
      onChange(name, value);
    }
    if (field.type === 'select' && field.dependentFields) {
      field.dependentFields.forEach(dependentField => {
        updateDynamicOptions(dependentField, value);
      });
    }
  };

  const updateDynamicOptions = (fieldName, selectedValue) => {

    const newOptions = filterOptions(fieldName, selectedValue);
    
    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      [fieldName]: newOptions,
    }));
  };

  const filterOptions = (fieldName, selectedValue) => {
    const field = fields.find(f => f.name === fieldName);

    if (!field) return [];
    return field.options
      .filter(opt => opt.dependentValue == selectedValue)
      
  };
  

  const renderInput = (field) => {
    const { type, name, options, component, link } = field;
    const handleChangeEvent = handleChange(field);
    const currentOptions = dynamicOptions[name] || options;

    switch (type) {
      case "textarea":
        return <textarea name={name} onChange={handleChangeEvent} className="w-full p-2 m-2 border rounded-md font-normal focus:outline-none focus:border-blue-900" />;
      case "select":
        return (
          <Select name={name} 
          options={currentOptions} 
          onChange={handleChangeEvent} 
          className="w-full p-2 text-black font-normal focus:outline-none focus:border-blue-900 m-2" />
        );
      case "radio":
        return options.map((option, index) => (
          <label key={index} className="flex items-center font-normal mt-2">
            <input type="radio" name={name} value={option.value} onChange={handleChangeEvent} className="mr-2" />
            {option.name}
          </label>
        ));
      case "checkbox":
        return options.map((option, index) => (
          <label key={index} className="flex items-center font-normal mt-2">
            <input type="checkbox" name={name} value={option.value} onChange={handleChangeEvent} className="mr-2" />
            {option.name}
          </label>
        ));
      case "link":
        return options.map((option, index) => (
          <div key={index}>
            <label className="flex items-center font-normal mt-2">
              <input type="checkbox" name={name} value={option.value} onChange={handleChangeEvent} className="mr-2" />
              {option.name}
            </label>
            <div className="mt-2">
              <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-900 underline">{name}</a>
            </div>
          </div>
        ));
      case "component":
        return component;
      default:
        return <input type={type.toLowerCase()} name={name} onChange={handleChangeEvent} className="w-full m-2 p-2 border rounded-md text-lg font-normal focus:outline-none focus:border-blue-900" />;
    }
  };



 // Reset Fields Function
 const resetFields = (fieldsToReset) => {
  const resetData = {};
  
  fieldsToReset.forEach((fieldName) => {
    resetData[fieldName] = initialValues[fieldName] || ''; 
  });

  setFormData((prevState) => ({
    ...prevState,
    ...resetData,
  }));

  // Optionally clear errors for the reset fields
  const resetErrors = { ...errors };
  fieldsToReset.forEach((fieldName) => {
    delete resetErrors[fieldName];
  });
  setErrors(resetErrors);
};

// Expose resetFields to parent via ref
useImperativeHandle(ref, () => ({
  resetFields,
}));


  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (sections) {
      sections.forEach((section) => {
        section.fields.forEach((field) => {
          const error = validateField(field, formData[field.name]);
          if (error) {
            newErrors[field.name] = error;
          }
        });
      });
    } else {
      fields.forEach((field) => {
        const error = validateField(field, formData[field.name]);
        if (error) {
          newErrors[field.name] = error;
        }
      });
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true); 
      try {
        if (onSubmit) {
          await onSubmit(formData);
        }
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className='grid grid-cols-2 items-center'>
      {sections ? (
        sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="space-y-4">
            <hr className='my-2' />
            <Typography variant='h4' className='font-bold text-2xl'>{section.title}</Typography>
            <hr className='my-2'/>
            {section.fields.map((field, fieldIndex) => (
              <div key={fieldIndex}>
                <Typography className='font-semibold text-lg' style={{ whiteSpace: 'pre-line' }}>{field.label || field.name}</Typography>
                {renderInput(field)}
                {errors[field.name] && <p className="text-red-500">{errors[field.name]}</p>}
              </div>
            ))}
          </div>
        ))
      ) : (
        fields.map((field, index) => (
          <div key={index}>
            <Typography variant='lead' className='font-semibold'>{field.label || field.name}</Typography>
            {renderInput(field)}
            {errors[field.name] && <p className="text-red-500">{errors[field.name]}</p>}
          </div>
        ))
      )}
       </div>
      <div className='flex justify-center'>
        <button
          type="submit"
          className="bg-blue-900 flex items-center text-lg text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50"
          disabled={loading} 
        >
          {loading ? <span className="loader"></span> : 'Submit'}
        </button>
      </div>
     
    </form>
  );
});
export default FormGenerator;
